export default {
  // useColorSchemeMediaQuery: true,
  breakpoints: ["760px", "1200px", "1400px", "2047px", "3839px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      "IBM Plex Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
    heading:
      "IBM Plex Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 40, 64, 72],
  colors: {
    text: "#aaa",
    darkText: "#262626",
    titles: "#eee",
    background: "#0d0d0d",
    primary: "#ffdd00",
    border: "#333",
    // modes: {
    //   light: {
    //     text: "#444",
    //     darkText: "#111",
    //     titles: "#111",
    //     background: "#fefefe",
    //     primary: "#ffdd00",
    //     border: "#eee",
    //   },
    // },
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    light: 300,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.6,
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    p: {
      fontSize: [1, 1, 2, 2, 4, 5],
      lineHeight: 1.6,
    },
    ul: {
      fontSize: [1, 1, 2, 2, 4, 5],
      lineHeight: 1.6,
    },
    ol: {
      fontSize: [1, 1, 2, 2, 4, 5],
      lineHeight: 1.6,
    },
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "text",
      fontSize: [2, 2, 2, 2, 4, 5],
    },
    h2: {
      variant: "text.heading",
      fontSize: [2, 2, 2, 2, 4, 5],
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "heading",
      fontSize: [2, 2, 2, 2, 4, 5],
      fontWeight: "bold",
    },
  },
}
